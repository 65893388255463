import { CameraView, DashboardCamera } from "lib/avatar/camera";
import { getCameraDistanceForViewportHeight } from "lib/three";
import { useEffect, useMemo } from "react";
import { useAvatarContext } from "render/pages/DashboardPage/context/AvatarContext";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";

type Locations = Partial<Record<Layer, CameraView>>;

interface CameraControlProps {
  camera: DashboardCamera;
  layer: Layer;
}

export function CameraControl({ camera, layer }: CameraControlProps) {
  const smpl = useAvatarContext().smpl;

  const views = useMemo(() => {
    const areas = smpl.front.areas;
    if (!areas) {
      return;
    }

    const top = areas.top.getCenter(new THREE.Vector3());
    const height = top.z;

    const portrait = new CameraView();
    areas.forehead.getCenter(portrait.focus);
    portrait.focus.z -= 250;
    portrait.pos.z = portrait.focus.z;
    portrait.pos.y = -1500;

    const fullbody = new CameraView();
    areas.forehead.getCenter(fullbody.focus);
    fullbody.focus.z = fullbody.focus.z / 2 - 50;
    fullbody.pos.set(0, -4500, 700);

    fullbody.pos.y = -getCameraDistanceForViewportHeight(
      fullbody.fov,
      height + 600
    );
    portrait.pos.y = -getCameraDistanceForViewportHeight(
      portrait.fov,
      height / 2.5
    );

    const diabetes = new CameraView();
    areas.forehead.getCenter(diabetes.focus);
    diabetes.focus.z -= 450;
    diabetes.focus.x += 300;
    diabetes.pos.z = diabetes.focus.z + 200;
    diabetes.pos.y = -2200;

    const lesionMacro = new CameraView();
    areas.forehead.getCenter(lesionMacro.focus);
    lesionMacro.focus.z -= 450;
    lesionMacro.focus.x += 175;
    lesionMacro.focus.x += 540;
    lesionMacro.pos.set(0, -2200, 1750);

    const lesion = lesionMacro.clone();
    lesion.focus.x += 2000;

    const heart = fullbody.clone();
    areas.heart.getCenter(heart.focus);
    heart.focus.y = 0;
    heart.focus.x += 60;
    heart.pos.copy(heart.focus);
    heart.pos.y -= 400;

    return {
      [Layer.Body]: fullbody,
      [Layer.Cardio]: fullbody,
      [Layer.Circulation]: fullbody,
      [Layer.ArterialHealth]: fullbody,
      [Layer.Cholesterol]: fullbody,
      [Layer.Diabetes]: diabetes,
      [Layer.Heart]: heart,
      [Layer.Identity]: portrait,
      [Layer.PulseWaves]: fullbody,
      [Layer.Skin]: fullbody,
      [Layer.LesionLibrary]: fullbody,
    } as Locations;
  }, [smpl]);

  useEffect(() => {
    const view = views?.[layer];
    if (!view) {
      return;
    }

    if (layer === Layer.Identity) {
      camera.setSpeed("slow");
    } else {
      camera.setSpeed("fast");
    }

    camera.moveTo(view);
  }, [camera, layer, views]);

  return null;
}
